.win-modal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content:center;
  align-items: center;
  background: rgba(0,0,0,0.9);
  &-inside {
    padding: 5vw;
    cursor: pointer;
    div {
      text-align: center;
      font-family: 'Londrina Solid', cursive;
      color: #ffffff;
      font-size: 6vw;
    }
    img {
      display: block;
      width: 480px;
      max-width: 50vw;
      margin: 0 auto;
    }
  }
}