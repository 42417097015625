.admin {
  overflow: auto;
  height: 100vh;
  label {
    margin-right: 8px;
  }
  input {
    margin-right: 24px;
  }

  textarea {
    display: block;
    width: 800px;
    height: 200px;
  }
}